import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OfficeLocation } from "@/api/locations";
import { Lock } from "@/api/locks";
import { LockSystem } from "@/api/lockSystems/lockSystems.ts";
import { Office } from "@/api/offices";
import { OfficeType } from "@/api/officeTypes";
import { Renter } from "@/api/renters";
import { TicketCategory } from "@/api/tickets/tickets.ts";
import { UserRole } from "@/api/userRoles/userRoles.ts";
import { User } from "@/api/users";
import { RenterRequisite, Requisite } from "@/api/requisites/requisites.ts";
import { Billing } from "@/api/billing";
import { Agreement } from "@/api/agreements";
import { LandlordUser } from "@/api/businessProfiles";

type TicketCategoryStore = TicketCategory[];
type LocationStore = OfficeLocation[];
type LockStore = Lock[];
type LockSystemStore = LockSystem[];
type OfficeStore = Office[];
type OfficeTypeStore = OfficeType[];
type RenterStore = Renter[];
type UserStore = User[];
type UserRoleStore = UserRole[];
type RequisitesStore = Requisite[];
type RenterRequisitesStore = RenterRequisite[];
type PaymentsStore = Billing[];
type AgreementsStore = Agreement[];
type LandlordsStore = LandlordUser[];
type DepositsStore = Billing[];

interface HandbooksStoreState {
  ticketCategory: TicketCategoryStore,
  location: LocationStore,
  lock: LockStore,
  lockSystem: LockSystemStore,
  office: OfficeStore,
  officeType: OfficeTypeStore,
  renter: RenterStore,
  user: UserStore,
  userRole: UserRoleStore,
  requisites: RequisitesStore,
  renterRequisites: RenterRequisitesStore,
  payments: PaymentsStore,
  agreements: AgreementsStore,
  landlords: LandlordsStore,
  deposits: DepositsStore
};

const initialState: HandbooksStoreState = {
  ticketCategory: [],
  location: [],
  lock: [],
  lockSystem: [],
  office: [],
  officeType: [],
  renter: [],
  user: [],
  userRole: [],
  requisites: [],
  payments: [],
  agreements: [],
  renterRequisites: [],
  landlords: [],
  deposits: []
};

const handbooksSlice = createSlice({
  name: 'handbooks',
  initialState,
  reducers: {
    setTicketCategories(state, action: PayloadAction<TicketCategoryStore>) {
      state.ticketCategory = action.payload;
    },
    setLocations(state, action: PayloadAction<LocationStore>) {
      state.location = action.payload;
    },
    setLocks(state, action: PayloadAction<LockStore>) {
      state.lock = action.payload;
    },
    setLockSystems(state, action: PayloadAction<LockSystemStore>) {
      state.lockSystem = action.payload;
    },
    setOffices(state, action: PayloadAction<OfficeStore>) {
      state.office = action.payload;
    },
    setOfficeTypes(state, action: PayloadAction<OfficeTypeStore>) {
      state.officeType = action.payload;
    },
    setRenters(state, action: PayloadAction<RenterStore>) {
      state.renter = action.payload;
    },
    setUsers(state, action: PayloadAction<UserStore>) {
      state.user = action.payload;
    },
    setUserRoles(state, action: PayloadAction<UserRoleStore>) {
      state.userRole = action.payload;
    },
    setRequisites(state, action: PayloadAction<RequisitesStore>) {
      state.requisites = action.payload;
    },
    setPayments(state, action: PayloadAction<PaymentsStore>) {
      state.payments = action.payload;
    },
    setDeposits(state, action: PayloadAction<DepositsStore>) {
      state.deposits = action.payload;
    },
    setAgreements(state, action: PayloadAction<AgreementsStore>) {
      state.agreements = action.payload;
    },
    setRenterRequisites(state, action: PayloadAction<RenterRequisitesStore>) {
      state.renterRequisites = action.payload;
    },
    setLandlords(state, action: PayloadAction<LandlordsStore>) {
      state.landlords = action.payload;
    },
  }
});

export const {
  setTicketCategories,
  setLocations,
  setLocks,
  setLockSystems,
  setOffices,
  setOfficeTypes,
  setRenters,
  setUsers,
  setUserRoles,
  setRequisites,
  setPayments,
  setAgreements,
  setRenterRequisites,
  setLandlords,
  setDeposits
} = handbooksSlice.actions;

export default handbooksSlice.reducer;