import { useAppSelector } from "@/hooks/useAppSelector";
import { AutocompleteProps, Autocomplete, AutocompleteItem } from "@nextui-org/react";
import { useMemo } from "react";
import { CodependentHandbookProps } from "../modals/modalBuilder";
import { Agreement } from "@/api/agreements";

export function CodependentAgreementHandbook(props: CodependentHandbookProps & AutocompleteProps<Agreement>) {
  const items = useAppSelector(state => state.handbooks.agreements);

  const finalItems = useMemo(() => {
    const renterId = Number(props.deps[0]) || 0;
    return items.filter(i => i.renter == renterId && i.is_active == true);
  }, [props.deps, items]);

  return (
    <Autocomplete
      defaultItems={finalItems}
      label="Договор аренды"
      placeholder="Выберите договор аренды"
      variant="bordered"
      {...props}
    >
      {(item) => (
        <AutocompleteItem key={String(item.id)}>
          { item.number }
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
}