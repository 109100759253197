import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud"
import { Office } from "../offices";

export interface OfficeLocation {
  id: number;
  display_name: string;
  business_profile: number;
  address: string;
  city: string;
  metro_station?: string;
  district?: string;
  total_area: number;
}

export interface LocationCreate {
  display_name: string;
  business_profile: number;
  address: string;
  city: string;
  metro_station?: string;
  district?: string;
}

export interface LocationUpdate {
  display_name: string;
  business_profile: number;
  address: string;
  city: string;
  metro_station?: string;
  district?: string;
}

export function LocationsAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/locations/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<OfficeLocation[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/locations/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<OfficeLocation>;
    },
    create: async (locationData: LocationCreate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/locations/?business_profile_id=${businessProfileId}`, locationData, true) as ApiResponse<OfficeLocation>;
    },
    update: async (id: number, locationData: LocationUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/locations/${id}?business_profile_id=${businessProfileId}`, locationData, true) as ApiResponse<{}>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/locations/${id}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    },
    getOfficesByLocation: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/locations/${id}/offices?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Office[]>;
    }
  }
}
