import { NewCategoryHandbook } from "../handbooks/categoryHandbook";
import { NewLocationHandbook } from "../handbooks/locationHandbook";
import { NewLockHandbook } from "../handbooks/lockHandbook";
import { NewLockSystemHandbook } from "../handbooks/lockSystemHandbook";
import { NewOfficeHandbook } from "../handbooks/officeHandbook";
import { NewOfficeTypeHandbook } from "../handbooks/officeTypeHandbook";
import { NewRenterHandbook } from "../handbooks/renterHandbook";
import { NewRenterRequisitesHandbook } from "../handbooks/renterRequisitesHandbook";
import { NewRequisitesHandbook } from "../handbooks/requisitesHandbook";
import { NewBillingTypeHandbook } from "../handbooks/service/billingTypeHandbook";
import { NewCurrencyHandbook } from "../handbooks/service/currencyHandbook";
import { NewMonthHandbook } from "../handbooks/service/monthHandbook";
import { NewUserHandbook } from "../handbooks/userHandbook";
import { NewUserRoleHandbook } from "../handbooks/userRoleHandbook";

export type InputType =
  | "text"
  | "password"
  | "email"
  | "datetime-local"
  | "textarea"
  | "phone"
  | "office-type"
  | "requisites"
  | "user"
  | "renter"
  | "office"
  | "lock-system"
  | "lock"
  | "user-role"
  | "location"
  | "category"
  | "currency"
  | "boolean"
  | "month"
  | "datepicker"
  | "renterRequisites"
  | "billing-type"
  | "codependent";

export const handbookMap: Record<string, React.ComponentType<any>> = {
  office: NewOfficeHandbook,
  "office-type": NewOfficeTypeHandbook,
  renter: NewRenterHandbook,
  user: NewUserHandbook,
  "lock-system": NewLockSystemHandbook,
  lock: NewLockHandbook,
  "user-role": NewUserRoleHandbook,
  location: NewLocationHandbook,
  category: NewCategoryHandbook,
  month: NewMonthHandbook,
  currency: NewCurrencyHandbook,
  requisites: NewRequisitesHandbook,
  renterRequisites: NewRenterRequisitesHandbook,
  "billing-type": NewBillingTypeHandbook
};