import { Autocomplete, AutocompleteItem, AutocompleteProps } from "@nextui-org/react";

interface BillingType {
  id: number;
  display_name: string;
}

export function NewBillingTypeHandbook(props: AutocompleteProps<BillingType>) {
  const items: BillingType[] = [
    { id: 1, display_name: 'Регулярный' },
    { id: 2, display_name: 'Депозит' }
  ];

  return (
    <Autocomplete
      defaultItems={items}
      label="Тип платежа"
      placeholder="Выберите тип платежа"
      variant="bordered"
      {...props}
    >
      {(item) => (
        <AutocompleteItem key={item.id}>
          {item.display_name}
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
}