import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud"

export interface UserRole {
  id: number;
  name: string;
}

export function UserRolesAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/user-roles/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<UserRole[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/user-roles/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<UserRole>;
    }
  }
}
