import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Admin } from '@/api/auth/auth.ts';

interface UserState {
  currentUser: Partial<Admin>,
  permissions: number
}

const initialState: UserState = {
  currentUser: {
    first_name: "Not",
    last_name: "Loaded"
  },
  permissions: 0
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<Admin>) {
      state.currentUser = action.payload;
    },
    setPermissions(state, action: PayloadAction<number>) {
      state.permissions = action.payload;
    }
  },
});

export const { setUser, setPermissions } = userSlice.actions;

export default userSlice.reducer;