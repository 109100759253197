import { Chip, DateRangePicker, useDisclosure } from "@nextui-org/react";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TableBuilder } from "@/app/components/tables/tableBuilder";
import { api } from "@/api";
import { Billing, SentEntry } from "@/api/billing";
import useErrorHandling from "@/hooks/useErrorHandling";
import { ColumnType } from "@/app/components/tables/tableTypes";
import { EditBillModal } from "@/app/components/modals/bill/editBillModal";

export function BillingSentPage() {
  const [entries, setEntries] = useState<SentEntry[]>([]);

  const [activeDateFrom, setActiveDateFrom] = useState<string>("");
  const [activeDateTo, setActiveDateTo] = useState<string>("");
  const [activeDate, setActiveDate] = useState<any>(null);

  const [activeBilling, setActiveBilling] = useState<Billing | null>(null);

  const handleError = useErrorHandling();

  const createBillingModalDisclosure = useDisclosure();
  const editBillingModalDisclosure = useDisclosure();

  useEffect(() => {
    if(!activeDateFrom || !activeDateTo) return;
    api.billings.getAllSents(activeDateFrom, activeDateTo)
      .then(response => {
        setEntries(response.data);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    createBillingModalDisclosure.isOpen,
    editBillingModalDisclosure.isOpen,
    activeDateFrom,
    activeDateTo
  ]);

  const handleBillOpen = useCallback((id: number) => {
    api.billings.get(id)
      .then(response => {
        setActiveBilling(response.data);
        editBillingModalDisclosure.onOpen();
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, []);

  return (
    <>
      <EditBillModal disclosure={editBillingModalDisclosure} billing={activeBilling} />
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
          <div className="flex flex-row justify-between w-full items-center mb-4">
            <div className="flex flex-row gap-4 items-center">
              <span className="font-medium">Отправленные счета</span>
            </div>
          </div>
          <div className="flex flex-row gap-2 mb-2">
            <DateRangePicker
                label="Выберите отрезок времени"
                variant="bordered"
                onChange={e => {
                  setActiveDate(e);
                  setActiveDateFrom(`${e.start.year}-${e.start.month}-${e.start.day}`);
                  setActiveDateTo(`${e.end.year}-${e.end.month}-${e.end.day}`);
                }}
                value={activeDate}
              />
          </div>
          <div className="flex flex-col gap-2 mb-2">
            <TableBuilder
              displayOptions={false}
              removeWrapper={true}
              columns={[
                {
                  label: "Счёт",
                  key: "number",
                  type: ColumnType.Custom,
                  render(value, row) {
                    return <Chip
                      onClick={() => handleBillOpen(row.billingId)}
                      className="cursor-pointer"
                      variant="dot"
                      color="primary"
                      children={value}
                    />
                  },
                },
                {
                  label: "Получатель",
                  key: "userId",
                  type: ColumnType.User,
                  sortable: true
                },
                {
                  label: "Компания",
                  key: "renterId",
                  type: ColumnType.Renter,
                  sortable: true
                },
                {
                  label: "Метод",
                  key: "sentType",
                  type: ColumnType.String,
                  sortable: true
                },
                {
                  label: "Время",
                  key: "datetime",
                  type: ColumnType.DateTime,
                  sortable: true
                },
              ]}
              data={entries || []}
              rowsPerPage={10}
              defaultSortDescriptor={{
                column: "number",
                direction: "ascending"
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}