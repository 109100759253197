import { Autocomplete, AutocompleteItem, AutocompleteProps } from "@nextui-org/react";
import { UseFormRegisterReturn } from "react-hook-form";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { RenterRequisite } from "@/api/requisites/requisites.ts";
import { CodependentHandbookProps } from "../modals/modalBuilder";
import { useMemo } from "react";

export function CodependentRenterRequisitesHandbook(props: CodependentHandbookProps & AutocompleteProps<RenterRequisite>) {
  const renters = useAppSelector(state => state.handbooks.renter);
  const items = useAppSelector(state => state.handbooks.renterRequisites);

  const finalItems = useMemo(() => {
    const renterId = Number(props.deps[0]) || 0;
    
    return items.filter(i => i.renter_id == renterId).map(req => {
      const renterName = renters.find(r => r.id == req.renter_id)?.display_name || req.display_name;
  
      return {
        ...req,
        display_name: renterName == req.display_name ?
          req.display_name : `${renterName} - ${req.display_name}`
      };
    });
  }, [props.deps, items]);

  return (
    <Autocomplete
      defaultItems={finalItems}
      label="Реквизиты арендатора"
      placeholder="Выберите реквизиты"
      variant="bordered"
      {...props}
    >
      {(item) => (
        <AutocompleteItem key={String(item.id)}>
          {item.display_name}
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
}

export function NewRenterRequisitesHandbook(props: AutocompleteProps<RenterRequisite>) {
  const renters = useAppSelector(state => state.handbooks.renter);
  const items = useAppSelector(state => state.handbooks.renterRequisites).map(req => {
    const renterName = renters.find(r => r.id == req.renter_id)?.display_name || req.display_name;

    return {
      ...req,
      display_name: renterName == req.display_name ?
        req.display_name : `${renterName} - ${req.display_name}`
    };
  });

  return (
    <Autocomplete
      defaultItems={items}
      label="Реквизиты арендатора"
      placeholder="Выберите реквизиты"
      variant="bordered"
      {...props}
    >
      {(item) => (
        <AutocompleteItem key={String(item.id)}>
          {item.display_name}
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
}

export function RenterRequisitesHandbook({ register, isInvalid, errorMessage, defaultSelectedKey }: { register: UseFormRegisterReturn, isInvalid: boolean, errorMessage: string, defaultSelectedKey?: string }) {
  const items = useAppSelector(state => state.handbooks.renterRequisites);

  return (
    <Autocomplete
      {...register}
      defaultItems={items}
      label="Реквизиты арендатора" 
      placeholder="Выберите реквизиты"
      variant="bordered"
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      defaultSelectedKey={defaultSelectedKey}
    >
      {(item) =>
        <AutocompleteItem key={String(item.id)}>{`${item.display_name}`}</AutocompleteItem>
      }
    </Autocomplete>
  )
}