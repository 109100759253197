import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud"

export interface LockSystem {
  id: number;
  display_name: string;
  api_key: string;
}

export interface LockSystemUpdate {
  api_key: string;
}

export function LockSystemsAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/lock-systems/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<LockSystem[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/lock-systems/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<LockSystem>;
    },
    update: async (id: number, lockSystemData: LockSystemUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/lock-systems/${id}?business_profile_id=${businessProfileId}`, lockSystemData, true) as ApiResponse<LockSystem>;
    }
  }
}
