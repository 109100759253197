import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud"

export interface BillingBase {
  number: string;
  date: string;
  payer: number;
  provider: number | null;
  clientProvider: number | null;
  discount: number;
  period: string;
  amount: number;
  paid: boolean;
  description: string;
  office: number;
  year: string;
  type: BillingType;
  rentedOffice: number;
}

export interface Billing extends BillingBase {
  id: number;
}

export type BillingCreate = BillingBase;
export type BillingUpdate = Partial<BillingBase>;

export interface SubmitBillingProps {
  email: number[];
  whatsapp: number[];
}

export enum BillingType {
  Regular = 1,
  Deposite = 2
}

export interface SentEntry {
  id: number;
  userId: number;
  renterId: number;
  sentType: "email" | "wa";
  billingId: number;
  datetime: string;
  businessProfile: number;
}

export function BillingsAPI(baseUrl: string) {
  return {
    list: async (period: string, year: number, location: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/billings/?business_profile_id=${businessProfileId}&period=${period}&year=${year}&location=${location}`, undefined, true) as ApiResponse<Billing[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/billings/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Billing>;
    },
    create: async (billingData: BillingCreate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/billings/?business_profile_id=${businessProfileId}`, billingData, true) as ApiResponse<Billing>;
    },
    update: async (id: number, billingData: BillingUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/billings/${id}?business_profile_id=${businessProfileId}`, billingData, true) as ApiResponse<Billing>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/billings/${id}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    },
    submit: async (id: number, props: SubmitBillingProps) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/billings/submit/${id}?business_profile_id=${businessProfileId}`, props, true) as ApiResponse<{}>;
    },
    getDeposits: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/billings/deposits?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Billing[]>;
    },
    getAllSents: async (date_from: string, date_to: string) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/billings/sent?date_from=${date_from}&date_to=${date_to}&business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<SentEntry[]>;
    }
  }
}
