import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { lazy, Suspense } from "react";
import { Provider } from "react-redux";
import rootStore from "./store";
import { Layout } from "./layout";
import { BaseLayout } from "./layout/baseLayout";
import { ErrorBoundaryPage } from "./layout/errorBoundary";
import { DashboardLoader, IndexLoader, AuthLoader } from "./loaders";
import 'react-toastify/dist/ReactToastify.css';
import { BillingSentPage } from "./pages/dashboard/payments/sent";

const Fallback = () => <div>Загрузка...</div>;

const LoginPage = lazy(() => import("./pages").then(m => ({ default: m.LoginPage })));
const NewInviteHashPage = lazy(() => import("./pages/auth/invite/[hash]").then(m => ({ default: m.NewInviteHashPage })));
const NewAuthHomePage = lazy(() => import("./pages/auth").then(m => ({ default: m.NewAuthHomePage })));
const NewLoginPage = lazy(() => import("./pages/auth/login").then(m => ({ default: m.NewLoginPage })));
const NewRegisterPage = lazy(() => import("./pages/auth/register").then(m => ({ default: m.NewRegisterPage })));
const NewResetPage = lazy(() => import("./pages/auth/reset/reset").then(m => ({ default: m.NewResetPage })));
const NewVerifyPage = lazy(() => import("./pages/auth/reset/verify").then(m => ({ default: m.NewVerifyPage })));
const PrivacyPolicyPage = lazy(() => import("./pages/auth/privacyPolicy").then(m => ({ default: m.PrivacyPolicyPage })));

const DashboardDemo = lazy(() => import("./pages/dashboard/dashboard").then(m => ({ default: m.DashboardDemo })));
const SelectProfile = lazy(() => import("./pages/dashboard/selectProfile").then(m => ({ default: m.SelectProfile })));
const RequisitesPage = lazy(() => import("./pages/dashboard/requisites").then(m => ({ default: m.RequisitesPage })));
const LocationPage = lazy(() => import("./pages/dashboard/location/[id]").then(m => ({ default: m.LocationPage })));
const OfficePage = lazy(() => import("./pages/dashboard/location/office/[id]").then(m => ({ default: m.OfficePage })));
const LocksPage = lazy(() => import("./pages/dashboard/locks").then(m => ({ default: m.LocksPage })));
const LockLogsPage = lazy(() => import("./pages/dashboard/locks/[id]/[id]").then(m => ({ default: m.LockLogsPage })));
const LockPasscodesPage = lazy(() => import("./pages/dashboard/locks/[id]/passcodes").then(m => ({ default: m.LockPasscodesPage })));
const TicketsPage = lazy(() => import("./pages/dashboard/tickets").then(m => ({ default: m.TicketsPage })));
const TicketPage = lazy(() => import("./pages/dashboard/ticket/[id]").then(m => ({ default: m.TicketPage })));
const RentersUsersPage = lazy(() => import("./pages/dashboard/renters/renters").then(m => ({ default: m.RentersUsersPage })));
const RenterUsersPage = lazy(() => import("./pages/dashboard/renters/[id]/[id]").then(m => ({ default: m.RenterUsersPage })));
const RenterEmployeesPage = lazy(() => import("./pages/dashboard/renters/[id]/employees").then(m => ({ default: m.RenterEmployeesPage })));
const RenterOfficesPage = lazy(() => import("./pages/dashboard/renters/[id]/offices").then(m => ({ default: m.RenterOfficesPage })));
const RenterRequisitesPage = lazy(() => import("./pages/dashboard/renters/[id]/requisites").then(m => ({ default: m.RenterRequisitesPage })));
const SystemParamsPage = lazy(() => import("./pages/dashboard/system").then(m => ({ default: m.SystemParamsPage })));
const OfficeTypesPage = lazy(() => import("./pages/dashboard/officeTypes").then(m => ({ default: m.OfficeTypesPage })));
const UserRolePage = lazy(() => import("./pages/dashboard/userRoles").then(m => ({ default: m.UserRolePage })));
const LockSystemsPage = lazy(() => import("./pages/dashboard/lockSystems").then(m => ({ default: m.LockSystemsPage })));
const UsersPage = lazy(() => import("./pages/dashboard/users/users").then(m => ({ default: m.UsersPage })));
const UserProfilePage = lazy(() => import("./pages/dashboard/users/[id]/[id]").then(m => ({ default: m.UserProfilePage })));
const UserRolesEditPage = lazy(() => import("./pages/dashboard/users/[id]/roles").then(m => ({ default: m.UserRolesEditPage })));
const BillingPage = lazy(() => import("./pages/dashboard/payments").then(m => ({ default: m.BillingPage })));
const AgreementsPage = lazy(() => import("./pages/dashboard/agreements").then(m => ({ default: m.AgreementsPage })));
const OfficesPage = lazy(() => import("./pages/dashboard/offices").then(m => ({ default: m.OfficesPage })));
const LocationsPage = lazy(() => import("./pages/dashboard/locations").then(m => ({ default: m.LocationsPage })));
const DocumentTemplatesPage = lazy(() => import("./pages/dashboard/documents").then(m => ({ default: m.DocumentTemplatesPage })));
const DocumentTemplatePage = lazy(() => import("./pages/dashboard/documents/template").then(m => ({ default: m.DocumentTemplatePage })));
const MyProfilePage = lazy(() => import("./pages/dashboard/me").then(m => ({ default: m.MyProfilePage })));
const SettingsPage = lazy(() => import("./pages/dashboard/settings").then(m => ({ default: m.SettingsPage })));
const FinancePage = lazy(() => import("./pages/dashboard/finance/finance").then(m => ({ default: m.FinancePage })));
const MeetingRoomsPage = lazy(() => import("./pages/dashboard/meetingRooms").then(m => ({ default: m.MeetingRoomsPage })));
const MeetingRoomPage = lazy(() => import("./pages/dashboard/meetingRooms/[id]/[id]").then(m => ({ default: m.MeetingRoomPage })));
const PrintersPage = lazy(() => import("./pages/dashboard/printers").then(m => ({ default: m.PrintersPage })));
const TestPage = lazy(() => import("./pages/dashboard/test").then(m => ({ default: m.TestPage })));

const router = createBrowserRouter([
  {
    path: "/",
    element: <BaseLayout />,
    errorElement: <ErrorBoundaryPage />,
    children: [
      { path: "/", loader: AuthLoader, element: <Suspense fallback={<Fallback />}><LoginPage /></Suspense> },
      { path: "/invite/:hash", element: <Suspense fallback={<Fallback />}><NewInviteHashPage /></Suspense> }
    ]
  },
  {
    path: "/auth",
    element: <BaseLayout />,
    errorElement: <ErrorBoundaryPage />,
    children: [
      { path: "/auth", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><NewAuthHomePage /></Suspense> },
      { path: "/auth/login", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><NewLoginPage /></Suspense> },
      { path: "/auth/register", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><NewRegisterPage /></Suspense> },
      { path: "/auth/reset", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><NewResetPage /></Suspense> },
      { path: "/auth/reset/:username", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><NewVerifyPage /></Suspense> },
      { path: "/auth/privacy", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><PrivacyPolicyPage /></Suspense> }
    ]
  },
  {
    path: "/dashboard",
    element: <Layout />,
    loader: DashboardLoader,
    errorElement: <ErrorBoundaryPage />,
    children: [
      { path: "/dashboard", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><DashboardDemo /></Suspense> },
      { path: "/dashboard/requisites", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><RequisitesPage /></Suspense> },
      { path: "/dashboard/selectProfile", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><SelectProfile /></Suspense> },
      { path: "/dashboard/location/:id", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><LocationPage /></Suspense> },
      { path: "/dashboard/location/:id/office/:officeId", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><OfficePage /></Suspense> },
      { path: "/dashboard/locks", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><LocksPage /></Suspense> },
      { path: "/dashboard/locks/:id", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><LockLogsPage /></Suspense> },
      { path: "/dashboard/locks/:id/passcodes", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><LockPasscodesPage /></Suspense> },
      { path: "/dashboard/tickets", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><TicketsPage /></Suspense> },
      { path: "/dashboard/tickets/:id", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><TicketPage /></Suspense> },
      { path: "/dashboard/renters", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><RentersUsersPage /></Suspense> },
      { path: "/dashboard/renters/:id", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><RenterUsersPage /></Suspense> },
      { path: "/dashboard/renters/:id/employees", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><RenterEmployeesPage /></Suspense> },
      { path: "/dashboard/renters/:id/offices", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><RenterOfficesPage /></Suspense> },
      { path: "/dashboard/renters/:id/requisites", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><RenterRequisitesPage /></Suspense> },
      { path: "/dashboard/system", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><SystemParamsPage /></Suspense> },
      { path: "/dashboard/officeTypes", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><OfficeTypesPage /></Suspense> },
      { path: "/dashboard/userRoles", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><UserRolePage /></Suspense> },
      { path: "/dashboard/lockSystems", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><LockSystemsPage /></Suspense> },
      { path: "/dashboard/users", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><UsersPage /></Suspense> },
      { path: "/dashboard/users/:id", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><UserProfilePage /></Suspense> },
      { path: "/dashboard/users/:id/roles", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><UserRolesEditPage /></Suspense> },
      { path: "/dashboard/payments", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><BillingPage /></Suspense> },
      { path: "/dashboard/payments/sent", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><BillingSentPage /></Suspense> },
      { path: "/dashboard/rentedOffices", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><AgreementsPage /></Suspense> },
      { path: "/dashboard/offices", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><OfficesPage /></Suspense> },
      { path: "/dashboard/locations", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><LocationsPage /></Suspense> },
      { path: "/dashboard/documents", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><DocumentTemplatesPage /></Suspense> },
      { path: "/dashboard/documents/:id/fill", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><DocumentTemplatePage /></Suspense> },
      { path: "/dashboard/me", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><MyProfilePage /></Suspense> },
      { path: "/dashboard/settings", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><SettingsPage /></Suspense> },
      { path: "/dashboard/finance", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><FinancePage /></Suspense> },
      { path: "/dashboard/meetingRooms", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><MeetingRoomsPage /></Suspense> },
      { path: "/dashboard/meetingRooms/:id", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><MeetingRoomPage /></Suspense> },
      { path: "/dashboard/printers", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><PrintersPage /></Suspense> },
      { path: "/dashboard/test", loader: IndexLoader, element: <Suspense fallback={<Fallback />}><TestPage /></Suspense> },
    ]
  }
]);

export function App() {
  return (
    <Provider store={rootStore}>
      <RouterProvider router={router} />
    </Provider>
  );
}
