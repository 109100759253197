import { CodependentBaseField, ModalField } from "@/app/components/modals/modalBuilder";
import { Column, ActionsColumn, ColumnType } from "../app/components/tables/tableTypes";
import { BillingType } from "@/api/billing";

export function getNormalizedTime(date: string): string {
  return new Date(date).toLocaleString().slice(12, 17)
}

export function getNormalizedDate(date: string): string {
  return new Date(date).toLocaleString().replace(/\//g, ".").slice(0, -3)
}

export function getNormalizedDateTime(date: string): string {
  return new Date(date).toLocaleString().replace(/\//g, ".").slice(0, -3)
}

export function getPortal() {
  return document.getElementById("main")!
}

export function isActionsColumn(column: Column): column is ActionsColumn {
  return column.type === ColumnType.Actions;
}

export function hasPermission(requiredPermission: number, userPermissions: number) {
  return (userPermissions & requiredPermission) === requiredPermission;
}

export function hasAllPermissions(scopePermissions: number[], userPermissions: number): boolean {
  const requiredMask = scopePermissions.reduce((acc, perm) => acc | perm, 0);
  return (userPermissions & requiredMask) === requiredMask;
}

export function getEnvironment(): "production" | "development" {
  return (window.location.hostname.indexOf("dev") !== -1 || window.location.hostname.indexOf("localhost") !== -1) ? "development" : "production";
}

export function getAPIPort(): number {
  return getEnvironment() == "development" ? 8200 : 8100;
}

export function getBaseUrl(): string {
  return getEnvironment() == "development" ? "https://dev.api.elasticspace.app" : "https://api.elasticspace.app";
}

export function getCurrentMonth() {
  return new Date().getMonth();
}

export function getMonthInRussian(month: number) {
  const months = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
  ];

  return months[month];
}

export function getMonthInEnglish(month: number) {
  const months = [
    'january', 'february', 'march', 'april', 'may', 'june',
    'july', 'august', 'september', 'october', 'november', 'december'
  ];

  return months[month];
}

export function getRussianMonthFromEnglish(month: string) {
  const months = [
    'january', 'february', 'march', 'april', 'may', 'june',
    'july', 'august', 'september', 'october', 'november', 'december'
  ];

  return getMonthInRussian(months.indexOf(month));
}

export function getRussianMonthNumber(month: string) {
  const russianMonths = [
    'январь', 'февраль', 'март', 'апрель', 'май', 'июнь',
    'июль', 'август', 'сентябрь', 'окябрь', 'ноябрь', 'декабрь'
  ];

  return russianMonths.indexOf(month.toLowerCase());
}

export function getMonthNumber(month: string) {
  const months = [
    'january', 'february', 'march', 'april', 'may', 'june',
    'july', 'august', 'september', 'october', 'november', 'december'
  ];


  return months.indexOf(month.toLowerCase());
}

export const formatNumber = (num?: number) => (num ?? 0).toFixed(2);

export const summaryValue = (value: number | undefined) =>
  (value ?? 0).toLocaleString("ru", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export function sortAlphabeticallyByKey(array: any[], key: string) {
  return array.sort((a, b) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  });
}

export function isCodependentField(field: ModalField): field is CodependentBaseField {
  return field.type == "codependent";
}

export function getBillingType(type: BillingType) {
  switch(type) {
    case BillingType.Deposite:
      return "Депозит";
    case BillingType.Regular:
      return "Регулярный платёж";
    default:
      return "Неизвестный (report me!)";
  }
}