import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud"
import { User } from "../users";
import { RenterRequisite } from "../requisites/requisites";

export interface Renter {
  id: number;
  display_name: string;
  orgn: string;
  holder: number;
  phone_number: string;
  email: string;
  inn: string;
}

export interface RenterCreate {
  display_name: string;
  orgn: string;
  holder: number;
  phone_number: string;
  email: string;
}

export interface RenterUpdate {
  display_name: string;
  orgn: string;
  holder: number;
  phone_number: string;
  email: string;
}

export function RentersAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/renters/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Renter[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/renters/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Renter>;
    },
    create: async (renterData: RenterCreate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/renters/?business_profile_id=${businessProfileId}`, renterData, true) as ApiResponse<Renter>;
    },
    update: async (id: number, renterData: RenterUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/renters/${id}?business_profile_id=${businessProfileId}`, renterData, true) as ApiResponse<{}>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/renters/${id}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    },
    getByInn: async (ogrn: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/renters/inn/${ogrn}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Renter>;
    },
    getByOgrn: async (ogrn: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/renters/ogrn/${ogrn}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Renter>;
    },
    listEmployees: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/renters/${id}/users?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<User[]>;
    },
    listRequisites: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/renters/${id}/requisites?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<RenterRequisite[]>;
    }
  }
}
