import {
  Autocomplete,
  AutocompleteItem,
  Button, Checkbox, Link, Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure
} from "@nextui-org/react";
import { useCallback, useEffect, useState } from "react";
import { FaPlus, FaWhatsapp } from "react-icons/fa6";
import { getBaseUrl } from "@/utils";
import { useAppSelector } from "@/hooks/useAppSelector";
import useErrorHandling from "@/hooks/useErrorHandling";
import { toast } from "react-toastify";
import { User } from "@/api/users";
import { api } from "@/api";
import { FaMailBulk, FaTimes } from "react-icons/fa";

function SocialIcon({ socialKey }: { socialKey: string }) {
  switch(socialKey) {
    case 'email':
      return <FaMailBulk />
    
    case 'whatsapp':
      return <FaWhatsapp />

    default:
      return <FaPlus />
  }
}

export function SendBillModal({
  disclosure,
  paymentId,
  renterId
}: {
  disclosure: ReturnType<typeof useDisclosure>;
  paymentId: number;
  renterId: number;
}) {
  const handleError = useErrorHandling();

  const [loading, setLoading] = useState(false);

  const businessProfile = useAppSelector((state) => state.businessProfile.businessProfile);

  const [activeUser, setActiveUser] = useState<string>("");
  const [sendUsers, setSendUsers] = useState<{ id: number; display_name: string; number: string; mail: string; whatsapp: boolean; email: boolean; }[]>([]);
  const [users, setUsers] = useState<User[]>([]);

  const socials = [
    {
      key: "whatsapp",
      label: "WhatsApp"
    },
    {
      key: "email",
      label: "E-mail"
    }
  ] as { key: "whatsapp" | "email"; label: string; }[];

  const previewBill = useCallback(() => {
    setLoading(true);

    fetch(getBaseUrl() + `/admin/billings/preview/${paymentId}?`
      + new URLSearchParams({
        business_profile_id: businessProfile.id.toString(),
      }), {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem("access_token")
      }
    })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `bills-report-${Math.round(Date.now() / 1000)}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setLoading(false);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
        setLoading(false);
      });
  }, [paymentId]);

  const onHandle = useCallback(() => {
    setLoading(true);
    const email = sendUsers.filter(u => u.email == true).map(u => u.id);
    const whatsapp = sendUsers.filter(u => u.whatsapp == true).map(u => u.id);

    api.billings.submit(paymentId, { email, whatsapp })
      .then(res => {
        console.log(res);
        toast.success("Счёт успешно отправлен!");
        setLoading(false);
        disclosure.onClose();
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
        setLoading(false);
      });
  }, [previewBill, sendUsers]);

  useEffect(() => {
    if(!renterId || !paymentId) return;

    api.renters.listEmployees(renterId)
      .then(data => {
        setUsers(data.data);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
        setLoading(false);
      });
  }, [renterId, paymentId]);

  useEffect(() => {
    const user = users.find(u => u.id == +activeUser);
    if (user) {
      setSendUsers((users) => {
        return [...users, { id: user.id, display_name: `${user.first_name} ${user.last_name}`, whatsapp: false, email: false, number: user.phone_number, mail: user.email }];
      });
    }

    setActiveUser("");

    if (document.activeElement instanceof HTMLElement)
      document.activeElement.blur();
  }, [activeUser]);

  const toggleSocial = useCallback((id: number, social: "whatsapp" | "email") => {
    setSendUsers((users) => {
      const updatedUsers = users.map((user) =>
        user.id === id ? { ...user, [social]: !user[social] } : user
      );
      return updatedUsers;
    });
  }, []);

  const removeUser = useCallback((id: number) => {
    const users = sendUsers.filter(user => user.id !== id);
    setSendUsers(users);
  }, [sendUsers]);

  return (
    <>
      <Modal
        isOpen={disclosure.isOpen}
        onOpenChange={disclosure.onOpenChange}
        portalContainer={document.getElementById("main")!}
        backdrop="blur"
        scrollBehavior="outside"
        isDismissable={false}
        classNames={{
          backdrop: "z-[500]",
          wrapper: "z-[500]"
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Отправка счёта</ModalHeader>
              <ModalBody className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <span>Вы создали платёж с указанием счёта, для которого включено автоматическое создание документа (счёта). Вы хотите отправить документ на указанные мессенджеры?</span>
                  <Link className="cursor-pointer" onClick={previewBill} showAnchorIcon>Как будет выглядеть отправленный счёт?</Link>

                  <Autocomplete
                    defaultItems={users.filter(user => !sendUsers.find(sU => sU.id == user.id ))}
                    selectedKey={activeUser}
                    onSelectionChange={(key) => setActiveUser(key?.toString() || "")}
                    label="Пользователь"
                    placeholder="Выберите пользователя"
                    variant="bordered"
                    className="mt-2"
                  >
                    {(item) => (
                      <AutocompleteItem key={String(item.id)}>
                        {`${item.first_name} ${item.last_name}`}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>

                  <div className="flex flex-col gap-2 mt-2">
                    {sendUsers.map((user, index) => (
                      <div key={index} className={`flex flex-row gap-2 items-center border-zinc-500 border-opacity-10 ${sendUsers.length != index + 1 ? 'pb-4 border-b-2' : ''}`}>
                        <div className="flex flex-col w-full gap-2">
                          <span>{ user.display_name }</span>
                          <div className="flex flex-col gap-2 w-full">
                            {socials.map(social => (
                              <Checkbox
                              isSelected={user[social.key]}
                              onChange={() => toggleSocial(user.id, social.key)}
                              >
                                <span className="flex flex-row gap-1 items-center"><SocialIcon socialKey={social.key} /> { social.key == "email" ? user.mail : user.number }</span>
                              </Checkbox>
                            ))}
                          </div>
                        </div>
                        <Button
                          size="sm"
                          startContent={<FaTimes />}
                          variant="flat"
                          onClick={() => removeUser(user.id)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="flex flex-grow flex-row gap-2">
                  <Button color="default" onClick={onClose} variant="flat">
                    Закрыть
                  </Button>
                </div>
                <Button
                  color="success"
                  variant="flat"
                  startContent={<FaPlus />}
                  onClick={onHandle}
                  isLoading={loading}
                >
                  Отправить
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
