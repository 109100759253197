import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";
import { TableBuilder } from "../../tables/tableBuilder";
import { Renter } from "@/api/renters";
import { UserChip } from "../userChip";
import { ColumnType } from "../../tables/tableTypes";

export function RenterModal({ isOpen, onOpenChange, item }: { isOpen: boolean, onOpenChange: (open: boolean) => void, item: Renter }) {
  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} portalContainer={document.getElementById("main")!} backdrop="blur" scrollBehavior="outside" size="3xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Просмотр компании</ModalHeader>
              <ModalBody>
                <div className="flex flex-col gap-1 mb-2">
                  <TableBuilder
                    displayOptions={false}
                    displaySearch={false}
                    columns={[
                      {
                        label: "Название",
                        key: "display_name",
                        type: ColumnType.String
                      },
                      {
                        label: "ОГРН",
                        key: "orgn",
                        type: ColumnType.String
                      },
                      {
                        label: "ИНН",
                        key: "inn",
                        type: ColumnType.String
                      },
                      {
                        label: "Владелец",
                        key: "holder",
                        render(value, _row) {
                          return <UserChip userId={value} />
                        },
                        type: ColumnType.Custom
                      },
                      {
                        label: "Номер телефона",
                        key: "phone_number",
                        type: ColumnType.String
                      },
                      {
                        label: "Email",
                        key: "email",
                        type: ColumnType.String
                      },
                    ]}
                    data={[item]}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button variant="flat" color="default" onClick={onClose}>Закрыть</Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}