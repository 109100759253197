import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud"

export interface LinkedPrinter {
  id: number;
  printer_id: string;
  location_id: number;
  floor: number;
  enabled: boolean;
}

export interface UnlinkedPrinter {
  id: string;
  enabled: boolean;
  url: string;
}

export interface PrinterCreate {
  printer_id: string;
  location_id: number;
  floor: number;
  enabled: boolean;
}

export interface PrinterUpdate {
  printer_id: string;
  location_id: number;
  floor: number;
  enabled: boolean;
}

export function PrintersAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/printers/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<LinkedPrinter[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/printers/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<LinkedPrinter>;
    },
    create: async (printerData: PrinterCreate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/printers/?business_profile_id=${businessProfileId}`, printerData, true) as ApiResponse<LinkedPrinter>;
    },
    update: async (id: number, printerData: PrinterUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/printers/${id}?business_profile_id=${businessProfileId}`, printerData, true) as ApiResponse<{}>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/printers/${id}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    },
    getUnlinked: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/printers/unlinked/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<UnlinkedPrinter[]>;
    }
  }
}
