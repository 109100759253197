import crud, { ApiResponse } from "../crud"

export interface Token {
  access_token: string;
  token_type: string;
}

export interface Admin {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  telegram: number;
  password: string;
}

export interface RegisterAdminParams {
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}

type PermissionsReponse = [
  {
    business_profile_id: number,
    permissions: number
  }
];

export enum AdminPermissions {
  ManageLandlordProfile = 1,
  ManageLandlordProperties = 2,
  ManageLandlordTenants = 4,
  ViewLandlordReports = 8,
  ManageLandlordAdmins = 16,
  ManageLandlordLocks = 32,
  ViewManageLandlordProfile = 64,
  ManageLandlordRenters = 128,
  AccessCommonLocks = 256,
  AccessLandlordLocks = 512,
  ViewRentedProperties = 1024,
  ManageRenterEmployees = 2048,
  RequestMaintenance = 4096,
  ViewRentalInvoices = 8192,
  ParticipateInSurveys = 16384,
  ViewRegisteredProfile = 32768,
  CreatingDocuments = 65536,
  ManageLandlordHardware = 131072,
  ViewLandlordTickets = 262144,
  ShowBusinessProfiles = 524288,
  AccessNotifications = 1048576,
  AccessRequisites = 2097152,
  ViewLandlordProperties = 4194304,
  SilentLock = 8388608
};

export function AuthAPI(baseUrl: string) {
  return {
    login: async (username: string, password: string) => {
      const formData = new URLSearchParams();
      formData.append("username", username);
      formData.append("password", password);
      
      return await crud.post(`${baseUrl}/auth/login`, formData, false, "application/x-www-form-urlencoded") as Token;
    },
    logout: async () => {
      return await crud.post(`${baseUrl}/auth/logout`, {}, true) as ApiResponse<{}>;
    },
    validateToken: async () => {
      return await crud.post(`${baseUrl}/auth/validate`, {}, true) as ApiResponse<{}>;
    },
    getCurrentAdminUserInfo: async () => {
      return await crud.get(`${baseUrl}/auth/me`, undefined, true) as ApiResponse<Admin>;
    },
    getMyPermissions: async () => {
      return await crud.get(`${baseUrl}/auth/permissions`, undefined, true) as ApiResponse<PermissionsReponse>;
    },
    generateTelegramLink: async() => {
      return await crud.get(`${baseUrl}/auth/generate-telegram-link`, undefined, true) as ApiResponse<{link: string}>;
    },
    register: async (params: RegisterAdminParams) => {
      return await crud.post(`${baseUrl}/auth/register`, params, false) as ApiResponse<Admin>;
    },
    connectBusinessProfile: async (hash: string) => {
      return await crud.post(`${baseUrl}/auth/connect-business-profile/${hash}`, null, true) as ApiResponse<{}>;
    },
    updateInfo: async (info: Partial<Admin>) => {
      return await crud.put(`${baseUrl}/auth/me`, info, true) as ApiResponse<{}>;
    },
    requestReset: async (data: { username: string }) => {
      return await crud.post(`${baseUrl}/auth/reset-password`, data, false) as ApiResponse<{}>;  
    }, 
    verifyReset: async (data: { username: string, code: number, new_password: string }) => {
      return await crud.post(`${baseUrl}/auth/reset-password-verify`, data, false) as ApiResponse<{}>;  
    }
  }
}
