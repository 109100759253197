import { Autocomplete, AutocompleteItem, AutocompleteProps } from "@nextui-org/react";
import { UseFormRegisterReturn } from "react-hook-form";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { CodependentHandbookProps } from "../modals/modalBuilder";
import { useEffect, useState } from "react";
import { api } from "@/api";
import { User } from "@/api/users";

export function CodependentRenterEmployeeHandbook(props: CodependentHandbookProps & AutocompleteProps<User>) {
  const [items, setItems] = useState<User[]>([]);
  const [errorFlag, setErrorFlag] = useState<boolean>(false);

  useEffect(() => {
    const renterId = Number(props.deps[0]) || 0;
    if(!renterId) return;

    api.renters.listEmployees(renterId)
      .then(data => {
        setItems(data.data);
      })
      .catch(err => {
        setErrorFlag(true);
        console.error(err);
      });
  }, [props.deps]);

  return (
    <Autocomplete
      defaultItems={items}
      label="Пользователь"
      placeholder={errorFlag ? "Ошибка при загрузке, проверьте консоль" : "Выберите пользователя"}
      variant="bordered"
      {...props}
    >
      {(item) => (
        <AutocompleteItem key={String(item.id)}>
          { `${item.first_name} ${item.last_name}` }
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
}

export function NewUserHandbook(props: AutocompleteProps<User>) {
  const items = useAppSelector(state => state.handbooks.user);

  return (
    <Autocomplete
      defaultItems={items}
      label="Пользователь"
      placeholder="Выберите пользователя"
      variant="bordered"
      {...props}
    >
      {(item) => (
        <AutocompleteItem key={String(item.id)}>
          {`${item.first_name} ${item.last_name}`}
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
}

export function UserHandbook({ register, isInvalid, errorMessage, defaultSelectedKey }: { register: UseFormRegisterReturn, isInvalid: boolean, errorMessage: string, defaultSelectedKey?: string }) {
  const items = useAppSelector(state => state.handbooks.user);

  return (
    <Autocomplete
      {...register}
      defaultItems={items}
      label="Пользователь" 
      placeholder="Выберите пользователя"
      variant="bordered"
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      defaultSelectedKey={defaultSelectedKey}
    >
      {(item) =>
        <AutocompleteItem key={String(item.id)}>{`${item.first_name} ${item.last_name}`}</AutocompleteItem>
      }
    </Autocomplete>
  )
}