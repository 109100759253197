import { getBaseUrl } from "../utils";
import { LandlordsAPI } from "./landlords";
import { AnalyticsAPI } from "./analytics";
import { AuthAPI } from "./auth/auth";
import { BillingsAPI } from "./billing";
import { BusinessProfilesAPI } from "./businessProfiles";
import { LocationsAPI } from "./locations";
import { LockSystemsAPI } from "./lockSystems/lockSystems";
import { LocksAPI } from "./locks";
import { MeetingRoomsAPI } from "./meetingRooms";
import { NotificationsAPI } from "./notifications";
import { OfficeTypesAPI } from "./officeTypes";
import { OfficesAPI } from "./offices";
import { PrintersAPI } from "./printers";
import { AgreementsAPI } from "./agreements";
import { RentersAPI } from "./renters";
import { RequisitesAPI } from "./requisites/requisites";
import { DocumentationTemplatesAPI } from "./templates";
import { TicketsAPI } from "./tickets/tickets";
import { UserRolesAPI } from "./userRoles/userRoles";
import { UsersAPI } from "./users";

const baseUrl = getBaseUrl() + "/business";

export const api = {
  auth: AuthAPI(baseUrl),
  businessProfiles: BusinessProfilesAPI(baseUrl),
  users: UsersAPI(baseUrl),
  offices: OfficesAPI(baseUrl),
  agreements: AgreementsAPI(baseUrl),
  renters: RentersAPI(baseUrl),
  billings: BillingsAPI(baseUrl),
  locations: LocationsAPI(baseUrl),
  locks: LocksAPI(baseUrl),
  lockSystems: LockSystemsAPI(baseUrl),
  officeTypes: OfficeTypesAPI(baseUrl),
  userRoles: UserRolesAPI(baseUrl),
  tickets: TicketsAPI(baseUrl),
  documents: DocumentationTemplatesAPI(baseUrl),
  landlords: LandlordsAPI(baseUrl),
  notifications: NotificationsAPI(baseUrl),
  requisites: RequisitesAPI(baseUrl),
  analytics: AnalyticsAPI(baseUrl),
  meetingRooms: MeetingRoomsAPI(baseUrl),
  printers: PrintersAPI(baseUrl),
}
