import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud";

interface BaseMeetingRoom {
  office_id: number;
  display_name: string;
  picture_url: string;
  features: string[];
}

export interface MeetingRoom extends BaseMeetingRoom {
  id: number;
  location_id: number;
  places: number;
  floor: number;
}

export interface CreateMeetingRoomParams extends BaseMeetingRoom {}

export interface UpdateMeetingRoomParams extends BaseMeetingRoom {}

interface BaseMeetingRoomRent {
  meeting_room_id: number;
  renter_id: number;
  user_id: number;
  rent_from: string;
  rent_to: string;
  description: string;
}

export interface MeetingRoomRent extends BaseMeetingRoomRent {
  id: number;
  created_at: string;
  updated_at: string;
}

export interface CreateMeetingRoomRent extends BaseMeetingRoomRent {}

export interface UpdateMeetingRoomRent extends BaseMeetingRoomRent {}

export interface InsertAdditionalTimeParams {
  renter_id: number;
  period: string;
  amount: number;
}

export function MeetingRoomsAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/meeting-rooms/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<MeetingRoom[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/meeting-rooms/${id}/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<MeetingRoom>;
    },
    create: async (data: CreateMeetingRoomParams) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/meeting-rooms/?business_profile_id=${businessProfileId}`, data, true) as ApiResponse<MeetingRoom>;
    },
    update: async (id: number, data: CreateMeetingRoomParams) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/meeting-rooms/${id}/?business_profile_id=${businessProfileId}`, data, true) as ApiResponse<MeetingRoom>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/meeting-rooms/${id}/?business_profile_id=${businessProfileId}`, true) as {};
    },
    getRents: async (roomId: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/meeting-rooms/${roomId}/rents/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<MeetingRoomRent[]>;
    },
    createRent: async (data: CreateMeetingRoomRent) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/meeting-rooms/rents/?business_profile_id=${businessProfileId}`, data, true) as ApiResponse<MeetingRoomRent>;
    },
    updateRent: async (rentId: number, data: UpdateMeetingRoomRent) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/meeting-rooms/rents/${rentId}/?business_profile_id=${businessProfileId}`, data, true) as ApiResponse<MeetingRoomRent>;
    },
    cancelRent: async (rentId: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/meeting-rooms/rents/${rentId}/?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    },
    getRentTime: async (renterId: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/meeting-rooms/rents/time/${renterId}/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<{ time: number }>;
    },
    insertAddTime: async (params: InsertAdditionalTimeParams) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/meeting-rooms/rents/time/${params.renter_id}/?business_profile_id=${businessProfileId}&period=${params.period}&renter_id=${params.renter_id}&amount=${params.amount}`, params, true) as ApiResponse<{ time: number }>;
    }
  }
}
