import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Button,
  useDisclosure,
  Checkbox,
  DatePicker,
  Textarea,
} from "@nextui-org/react";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { RegisterOptions, useForm, Controller } from "react-hook-form";
import { handbookMap, InputType } from "./builderTypes";
import { parseDate } from "@internationalized/date";
import { ConfirmDeleteModal } from "./service/confirmDeleteModal";
import { ConfirmEditModal } from "./service/confirmEditModal";
import { I18nProvider } from "@react-aria/i18n";
import { isCodependentField } from "@/utils";
import { HiPhone } from "react-icons/hi2";

export interface InputField {
  type: InputType;
  name: string;
  label: string;
  placeholder: string;
  startContent?: React.ReactNode;
  endContent?: React.ReactNode;
  isRequired?: boolean;
  pattern?: RegExp;
  patternMessage?: string;
}

export interface CodependentBaseField extends InputField {
  type: "codependent";
  deps: string[];
  element: any;
}

export type ModalField = InputField | CodependentBaseField;

export interface DefaultValues {
  [key: string]: any;
}

export interface ModalError {
  field: string;
  message: string;
}

export interface ModalBuilderProps {
  title: string;
  fields: ModalField[];
  defaultValues?: DefaultValues;
  isOpen: boolean;
  isSensitiveData: boolean;
  submitButtonText?: string;
  deleteButtonText?: string;
  modalErrors?: ModalError[];
  onSubmit: (data: any) => Promise<Boolean>;
  onDelete?: () => Promise<Boolean>;
  onOpenChange: (open: boolean) => void;
}

export interface CodependentHandbookProps {
  deps: string[];
}

export interface CodependentDependency {
  deps: string[];
  values: string[];
}

export function ModalBuilder({
  title,
  fields,
  defaultValues,
  isOpen,
  isSensitiveData,
  submitButtonText = "Создать",
  deleteButtonText = "Удалить",
  modalErrors = [],
  onSubmit,
  onDelete,
  onOpenChange,
}: ModalBuilderProps) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<DefaultValues>({ defaultValues });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DefaultValues | null>(null);

  const [codependentDeps, setCodependentDeps] = useState<{ [key: string]: CodependentDependency }>({});

  const editSensitiveDisclosure = useDisclosure();
  const deleteSensitiveDisclosure = useDisclosure();

  const values = watch();
  const stableValues = useMemo(() => ({ ...values }), [values]);

  useEffect(() => {
    setCodependentDeps((prevDeps) => {
      const newDeps = { ...prevDeps };
      let hasChanged = false;

      const keys = Object.keys(newDeps);

      fields.filter(field => field.type == "codependent").forEach(field => {
        if (isCodependentField(field)) {
          if (!keys.includes(field.name)) {
            newDeps[field.name] = {
              deps: field.deps,
              values: []
            };

            hasChanged = true;
          }
        }
      });

      return hasChanged ? newDeps : prevDeps;
    });

  }, [fields]);

  useEffect(() => {
    setCodependentDeps((prevDeps) => {
      let hasChanged = false;
      const newDeps = { ...prevDeps };

      Object.keys(newDeps).forEach(key => {
        const field = newDeps[key];

        const updatedValues = field.deps.map(dep => values[dep] || "");

        if (JSON.stringify(field.values) !== JSON.stringify(updatedValues)) {
          newDeps[key] = { ...field, values: updatedValues };
          hasChanged = true;
        }
      });

      return hasChanged ? newDeps : prevDeps;
    });
  }, [stableValues]);

  useEffect(() => {
    if (modalErrors.length) {
      modalErrors.forEach(({ field, message }) => {
        if(fields.find(mf => mf.name == field)) {
          setError(field, { type: "manual", message });
        }
      });
    } else {
      clearErrors();
    }
  }, [modalErrors, setError, clearErrors]);

  useEffect(() => {
    if (!isOpen) {
      setLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (defaultValues) {
      Object.entries(defaultValues).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [defaultValues, setValue]);

  const registerOptions = useMemo<Record<string, RegisterOptions>>(() => {
    const options: Record<string, RegisterOptions> = {};
    fields.forEach((field) => {

      options[field.name] = {};

      if (field.isRequired) {
        options[field.name].required = "Это поле обязательно для заполнения";
      }

      if (field.pattern) {
        options[field.name].pattern = {
          value: field.pattern,
          message: field.patternMessage || "Поле не соответствует формату!"
        }
      }
    });
    return options;
  }, [fields]);

  const handleSubmitSensitive = useCallback(
    async (formData: DefaultValues) => {
      setLoading(true);
      try {
        await onSubmit(formData);
      } finally {
        setLoading(false);
      }
    },
    [onSubmit]
  );

  const handleSensitiveDelete = useCallback(async () => {
    if (onDelete) {
      setLoading(true);
      try {
        await onDelete();
      } finally {
        setLoading(false);
      }
    }
  }, [onDelete]);

  const handleFormSubmit = useCallback(
    (formData: DefaultValues) => {
      if (isSensitiveData) {
        setData(formData);
        editSensitiveDisclosure.onOpen();
      } else {
        handleSubmitSensitive(formData);
      }
    },
    [isSensitiveData, editSensitiveDisclosure, handleSubmitSensitive]
  );

  const handleDelete = useCallback(() => {
    if (isSensitiveData) {
      deleteSensitiveDisclosure.onOpen();
    } else {
      handleSensitiveDelete();
    }
  }, [isSensitiveData, deleteSensitiveDisclosure, handleSensitiveDelete]);

  const getHandbookComponent = (type: InputType) => {
    return handbookMap[type];
  };

  const renderInput = useCallback(
    (field: InputField) => {
      switch (field.type) {
        case "text":
        case "email":
        case "datetime-local":
        case "password":
          return <Controller
            control={control}
            name={field.name}
            rules={registerOptions[field.name]}
            render={({ field: controllerField }) => (
              <Input
                variant="bordered"
                placeholder={field.placeholder}
                label={field.label}
                isRequired={field.isRequired}
                isInvalid={!!errors[field.name]}
                type={field.type}
                errorMessage={errors[field.name]?.message?.toString()}
                value={controllerField.value}
                onValueChange={controllerField.onChange}
              />
            )}
          />;

        case "phone":
          return <Controller
            control={control}
            name={field.name}
            rules={registerOptions[field.name]}
            render={({ field: controllerField }) => (
              <>
                <PhoneInputWithCountrySelect
                  value={controllerField.value}
                  onChange={(e) => controllerField.onChange(e)}
                  internationalIcon={HiPhone}
                  limitMaxLength
                  placeholder="Номер телефона"
                />
                {!!errors[field.name] && (
                  <span className="text-red-500">{ errors[field.name]?.message?.toString() }</span>
                )}
              </>
            )}
          />;

        case "codependent":
          if (isCodependentField(field)) {
            return <Controller
              control={control}
              name={field.name}
              rules={registerOptions[field.name]}
              render={({ field: controllerField }) => (
                <field.element
                  deps={codependentDeps[field.name]?.values || []}
                  isInvalid={!!errors[field.name]}
                  errorMessage={errors[field.name]?.message?.toString()}
                  onSelectionChange={(key: React.Key) => controllerField.onChange(key)}
                  selectedKey={String(controllerField.value)}
                />
              )}
            />;
          }
          return null;

        case "textarea":
          return <Controller
            control={control}
            name={field.name}
            rules={registerOptions[field.name]}
            render={({ field: controllerField }) => (
              <Textarea
                variant="bordered"
                placeholder={field.placeholder}
                label={field.label}
                isRequired={field.isRequired}
                isInvalid={!!errors[field.name]}
                type={field.type}
                errorMessage={errors[field.name]?.message?.toString()}
                value={controllerField.value}
                onValueChange={controllerField.onChange}
              />
            )}
          />;

        case "boolean":
          return (
            <Controller
              control={control}
              name={field.name}
              render={({ field: controllerField }) => (
                <Checkbox
                  {...controllerField}
                  isSelected={controllerField.value}
                  onChange={controllerField.onChange}
                  isInvalid={!!errors[field.name]}
                >
                  {field.label}
                </Checkbox>
              )}
            />
          );

        case "datepicker":
          return (
            <Controller
              control={control}
              name={field.name}
              render={({ field: controllerField }) => (
                <I18nProvider locale="ru-RU">
                  <DatePicker
                    label={field.label}
                    variant="bordered"
                    isRequired={field.isRequired}
                    onChange={(date) => controllerField.onChange(date.toString())}
                    value={controllerField.value ? parseDate(controllerField.value) : undefined}
                    isInvalid={!!errors[field.name]}
                    errorMessage={errors[field.name]?.message?.toString()}
                  />
                </I18nProvider>
              )}
            />
          );

        default:
          const HandbookComponent = getHandbookComponent(field.type);
          if (HandbookComponent) {
            return (
              <Controller
                control={control}
                name={field.name}
                render={({ field: controllerField }) => (
                  <HandbookComponent
                    isInvalid={!!errors[field.name]}
                    errorMessage={errors[field.name]?.message?.toString()}
                    onSelectionChange={(key: React.Key) => controllerField.onChange(key)}
                    selectedKey={String(controllerField.value)}
                  />
                )}
              />
            );
          }
          return null;
      }
    },
    [codependentDeps, control, errors, register, registerOptions, defaultValues]
  );

  return (
    <>
      <ConfirmEditModal
        isOpen={editSensitiveDisclosure.isOpen}
        onOpenChange={editSensitiveDisclosure.onOpenChange}
        callback={handleSubmitSensitive}
        data={data}
      />

      <ConfirmDeleteModal
        isOpen={deleteSensitiveDisclosure.isOpen}
        onOpenChange={deleteSensitiveDisclosure.onOpenChange}
        callback={handleSensitiveDelete}
      />

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        portalContainer={document.getElementById("main")!}
        classNames={{
          backdrop: "z-[500]",
          wrapper: "z-[500]"
        }}
        backdrop="blur"
        scrollBehavior="outside"
        isDismissable={false}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>{title}</ModalHeader>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <ModalBody>
                  {fields.map((field) => (
                    <div key={field.name} className="mb-2 block">
                      {renderInput(field)}
                    </div>
                  ))}
                </ModalBody>
                <ModalFooter>
                  <div className="flex flex-grow flex-row gap-2">
                    <Button color="default" onClick={onClose} variant="flat">
                      Закрыть
                    </Button>
                  </div>
                  {onDelete && (
                    <Button color="danger" onClick={handleDelete} variant="flat">
                      {deleteButtonText}
                    </Button>
                  )}
                  <Button color="success" type="submit" variant="flat" isLoading={loading}>
                    {submitButtonText}
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
