import { Autocomplete, AutocompleteItem, AutocompleteProps } from "@nextui-org/react";
import { UseFormRegisterReturn } from "react-hook-form";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { OfficeLocation } from "@/api/locations";
import { useMemo } from "react";

interface LocationHandbookProps {
  isFinancial: boolean;
}

export function NewLocationHandbook(props: LocationHandbookProps & AutocompleteProps<OfficeLocation>) {
  const rawItems = useAppSelector(state => state.handbooks.location);

  const items = useMemo(() => {
    if(props.isFinancial) {
      return [{ id: -1, display_name: "Все локации", address: "", business_profile: 1, city: "", total_area: 0 }, ...rawItems];
    }

    return rawItems;
  }, [props.isFinancial, rawItems]);

  return (
    <Autocomplete
      defaultItems={items}
      label="Локация"
      placeholder="Выберите локацию"
      variant="bordered"
      {...props}
    >
      {(item) =>
        <AutocompleteItem key={String(item.id)}>
          {item.display_name}
        </AutocompleteItem>
      }
    </Autocomplete>
  );
}

export function LocationHandbook({ register, isInvalid, errorMessage, defaultSelectedKey }: { register: UseFormRegisterReturn, isInvalid: boolean, errorMessage: string, defaultSelectedKey?: string }) {
  const items = useAppSelector(state => state.handbooks.location);

  return (
    <Autocomplete
      {...register}
      defaultItems={items}
      label="Локация"
      placeholder="Выберите локацию"
      variant="bordered"
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      defaultSelectedKey={defaultSelectedKey}
    >
      {(item) =>
        <AutocompleteItem key={String(item.id)}>{`${item.display_name}`}</AutocompleteItem>
      }
    </Autocomplete>
  )
}