import rootStore from "@/app/store";
import crud, { ApiLockLogResponse, ApiResponse } from "../crud"

export interface Lock {
  id: number;
  display_name: string;
  lock_system: number;
  lock_service_id: number;
  location: {
    id: number;
    display_name: string;
  } | null;
  shared: boolean;
}

export interface LockCreate {
  display_name: string;
  lock_system: number;
  lock_service_id: number;
  bussiness_profile_id: number;
  location_id: number;
  shared: boolean;
}

export interface LockUpdate {
  display_name: string;
  lock_system: number;
  lock_service_id: number;
  location_id: number;
  shared: boolean;
}

export interface LockPasscode {
  id: number;
  passcode: string;
  lock_id: number;
}

export interface LockPasscodeCreate {
  passcode: string;
  lock_id: number;
}

export interface LockPasscodeUpdate {
  passcode: string;
}

export interface LockLog {
  id: number;
  lock_id: number;
  user_id: number;
  admin_id: number;
  record_type: number;
  username: string;
  CREATED_AT: string;
  message: string;
}

export type LockLogResponse = LockLog[];

export enum LockLogRecordType {
  APP_UNLOCK = 1,
  PARKING_LOCK = 2,
  GATEWAY_UNLOCK = 3,
  PASSCODE_UNLOCK = 4,
  PARKING_LOCK_RAISE = 5,
  PARKING_LOCK_LOWER = 6,
  IC_CARD_UNLOCK = 7,
  FINGERPRINT_UNLOCK = 8,
  WRISTBAND_UNLOCK = 9,
  MECHA_KEY_UNLOCK = 10,
  BLUETOOTH_LOCK = 11,
  SECOND_GATEWAY_UNLOCK = 12,
  UNEXPECTED_UNLOCK = 29,
  DOOR_MAGNET_CLOSE = 30,
  DOOR_MAGNET_OPEN = 31,
  OPEN_FROM_INSIDE = 32,
  LOCK_BY_FINGERPRINT = 33,
  LOCK_BY_PASSCODE = 34,
  LOCK_BY_IC_CARD = 35,
  LOCK_BY_MECHA_KEY = 36,
  REMOTE_CONTROL = 37,
  TAMPER_ALERT = 44,
  AUTO_LOCK = 45,
  UNLOCK_BY_KEY = 46,
  LOCK_BY_KEY = 47,
  INVALID_PASSCODE = 48
};

export function LocksAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/locks/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Lock[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/locks/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Lock>;
    },
    unlock: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/locks/${id}/unlock?business_profile_id=${businessProfileId}`, {}, true) as ApiResponse<Lock>;
    },
    create: async (lockData: any) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      const params = {...lockData};
      params.business_profile_id = businessProfileId;

      return await crud.post(`${baseUrl}/locks/?business_profile_id=${businessProfileId}`, params, true) as ApiResponse<Lock>;
    },
    update: async (id: number, lockData: any) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      const params = {...lockData};
      params.business_profile_id = businessProfileId;

      return await crud.put(`${baseUrl}/locks/${id}?business_profile_id=${businessProfileId}`, params, true) as ApiResponse<{}>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/locks/${id}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    },
    listPasscodes: async (lockId: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/locks/${lockId}/passcodes?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<LockPasscode[]>;
    },
    createPasscode: async (lockId: number, passcodeData: LockPasscodeCreate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/locks/${lockId}/passcodes?business_profile_id=${businessProfileId}`, passcodeData, true) as ApiResponse<LockPasscode>;
    },
    updatePasscode: async (lockId: number, passcodeId: number, passcodeData: LockPasscodeUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/locks/${lockId}/passcodes/${passcodeId}?business_profile_id=${businessProfileId}`, passcodeData, true) as ApiResponse<{}>;
    },
    deletePasscode: async (lockId: number, passcodeId: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/locks/${lockId}/passcodes/${passcodeId}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    },
    getLogs: async (lockId: number, offset: number = 0, limit: number = 10, user_id?: number, admin_id?: number, time_from?: string, time_to?: string) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/locks/${lockId}/logs?offset=${offset}&limit=${limit}&user_id=${user_id}&admin_id=${admin_id}&time_from=${time_from}&time_to=${time_to}&business_profile_id=${businessProfileId}`, undefined, true) as ApiLockLogResponse<LockLogResponse>;
    }
  }
}
